$grey-800: #323441;

[data-popper-arrow-inner='true'] {
  border: 1px solid $grey-800;
}

.tooltip_bottom {
  [data-popper-arrow-inner='true'] {
    border-bottom: 0;
    border-right: 0;
  }
}
.tooltip_top {
  [data-popper-arrow-inner='true'] {
    border-top: 0;
    border-left: 0;
  }
}
.tooltip_left {
  [data-popper-arrow-inner='true'] {
    border-bottom: 0;
    border-left: 0;
  }
}
.tooltip_right {
  [data-popper-arrow-inner='true'] {
    border-top: 0;
    border-right: 0;
  }
}

.scroll_shadows {
  // max-width: 200px;
  // overflow: auto;

  background:
    /* Shadow Cover LEFT */ linear-gradient(
      to right,
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    /* Shadow Cover RIGHT */ linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) right,
    /* Shadow LEFT */ radial-gradient(farthest-side at 0 50%, #eeeafb, rgba(0, 0, 0, 0)) left center,
    /* Shadow RIGHT */ radial-gradient(farthest-side at 100% 50%, #eeeafb, rgba(0, 0, 0, 0)) right
      center;

  background-repeat: no-repeat;
  background-size: 40px 100%, 40px 100%, 18px 100%, 18px 100%;
  background-attachment: local, local, scroll, scroll;
}
// SimpleSteps dots and line
.steps {
  border-top: 2px solid #3e33bb;
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  margin: 6px 0px;
}
.steps > li {
  position: relative;
}
.steps > li:after {
  content: '';
  width: 12px;
  height: 12px;
  background: white;
  position: absolute;
  border-radius: 12px;
  left: 50%;
  top: -7px;
  transform: translatex(-50%);
  border: 2px solid #3e33bb;
}
.steps > li.active:after {
  background: #3e33bb;
}
.steps > li:first-of-type:after {
  left: 50%;
}
.steps > li:last-of-type:after {
  left: -50%;
}
